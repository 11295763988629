<template>
  <div
    :class="`flex align-items-center justify-content-center status-icon p-2 ${status}`"
  >
    <i :class="`pi ${iconClass}`"></i>
  </div>
</template>

<script>
const STATS_ICONS = {
  success: 'pi-check',
  error: 'pi-times',
  unknown: 'pi-sync',
};

export default {
  props: ['status'],
  computed: {
    iconClass() {
      return STATS_ICONS[this.status];
    },
  },
};
</script>

<style scoped lang="scss">
.status-icon {
  border-radius: 50%;
}
.success {
  background-color: #38cf5b;
  color: grey;
}
.error {
  background-color: #fa2828;
  color: white;
}
.unknown {
  background-color: lightgrey;
  color: black;
}
</style>
