<template>
  <div class="grid crud-demo">
    <div class="col-12">
      <div class="card">
        <div class="containerClass">
          <OrganizationTopBar />
        </div>
        <Toast />
        <DataTable
          :sortField="sortField"
          :sortOrder="-1"
          ref="dt"
          :loading="loading"
          :totalRecords="totalRecords"
          :value="notifications"
          :lazy="true"
          dataKey="id"
          :paginator="true"
          :rows="10"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="[5, 10, 20]"
          responsiveLayout="scroll"
          v-model:expandedRows="expandedRows"
          @page="onFetch($event)"
          @sort="onFetch($event)"
          :rowClass="showExpander"
        >
          <template #empty> No se han encontrado notificaciones </template>
          <template #loading> Cargando notificaciones... </template>
          <template #expansion="slotProps">
            <div>
              <div class="grid">
                <div class="col-12">
                 Mensaje: <b>{{ slotProps.data.smsList[0].message }}</b>
                </div>
              </div>
            </div>
          </template>
          <Column :expander="true" headerStyle="width: 3em" />
          <Column field="SUCCESS" header="Estado" sortable>
            <template #body="{ data }">
              <div class="flex">
                <span v-tooltip="getSuccessTooltip(data)">
                  <StatusIcon :status="getIconClassType(data)" />
                </span>
              </div>
            </template>
          </Column>
          <Column field="METHOD" header="Método" sortable>
            <template #body="{ data }">
              <span v-tooltip="data.method">
                <i
                  :class="
                    data.method === 'SMS' ? 'pi pi-phone' : 'pi pi-envelope'
                  "
                ></i>
              </span>
            </template>
          </Column>
          <Column field="DATE" header="Fecha" sortable>
            <template #body="{ data }">
              {{ formatApiDateTimeForHuman(data.date) }}
            </template>
          </Column>
          <Column field="PHONE" header="Teléfono" sortable>
            <template #body="{ data }">
              {{ data.to.phone }}
            </template>
          </Column>
          <Column field="EMAIL" header="Email" sortable>
            <template #body="{ data }">
              {{ data.to.email }}
            </template>
          </Column>
          <Column field="NOTIFICATION_TYPE" header="Tipo" sortable>
            <template #body="{ data }">
              <div v-if="typeIcons[data.notificationType].icon">
                <span v-tooltip="typeIcons[data.notificationType].label">
                  <img
                    :src="typeIcons[data.notificationType].icon"
                    style="width: 1.5em"
                  />
                </span>
              </div>
              <div v-else>
                <span v-tooltip="typeIcons[data.notificationType].label">
                  <i
                    style="font-size: 1.2em; padding-left: 3px"
                    :class="typeIcons[data.notificationType].class"
                  >
                  </i>
                </span>
              </div>
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
  </div>
</template>

<script>
import autoBookingIcon from '../assets/icons/person-add.svg';
import manualBookingIcon from '../assets/icons/shop-plus.svg';
import api from '../api/Acutame';
import OrganizationTopBar from './OrganizationTopbar.vue';
import StatusIcon from '../components/StatusIcon.vue';
import { NOTIFICATION_LABEL } from '../constants/constants';
import { formatApiDateTimeForHuman } from '../utils/dates';

export default {
  components: {
    OrganizationTopBar: OrganizationTopBar,
    StatusIcon: StatusIcon,
  },
  mounted() {
    this.rows = this.$refs.dt.rows;
    this.fetchPage();
  },
  data() {
    let notificationLabelMap = {};
    NOTIFICATION_LABEL.forEach((notificationLabel) => {
      notificationLabelMap[notificationLabel.key] = notificationLabel.label;
    });
    return {
      id: this.$route.params.id,
      page: 0,
      notifications: [],
      totalRecords: null,
      method: null,
      loading: true,
      rows: null,
      sortField: 'DATE',
      sortOrder: -1,
      notificationLabelMap,
      formatApiDateTimeForHuman,
      expandedRows: [],
      typeIcons: {
        CREATE_BOOKING_MANUAL: {
          icon: manualBookingIcon,
          label: 'Reserva creada por punto de venta',
        },
        CREATE_BOOKING_AUTO: {
          icon: autoBookingIcon,
          label: 'Reserva creada por cliente',
        },
        ACCEPT_BOOKING: {
          class: 'pi pi-check-circle',
          label: 'Reserva aceptada',
        },
        ACCEPT_BOOKING_MANUAL: {
          class: 'pi pi-check-circle',
          label: 'Reserva manual aceptada',
        },
        REJECT_BOOKING: {
          class: 'pi pi-times-circle',
          label: 'Reserva rechazada',
        },
        CANCEL_BOOKING: {
          class: 'pi pi-user-minus',
          label: 'Reserva cancelada',
        },
        REMIND_BOOKING: {
          class: 'pi pi-bell',
          label: 'Recordatorio de reserva',
        },
      },
    };
  },
  methods: {
    showExpander(data) {
      return data.method === 'SMS' ? '' : 'no-expander';
    },
    getSuccessTooltip(notification) {
      if (notification.method === 'EMAIL')
        return notification.success === true ? 'Entregado' : 'Error';
      const sms = notification.smsList[0];
      const { status } = sms;
      if (['WAITING_REPORT', 'UNKNOWN'].includes(status))
        return 'Pendiente de recibir informe de estado';
      if (['DELIVERED', 'ACCEPTED'].includes(status)) return 'Entregado';
      return 'Error';
    },
    getIconClassType(notification) {
      if (notification.method === 'EMAIL')
        return notification.success === true ? 'success' : 'error';
      const sms = notification.smsList[0];
      const { status } = sms;
      if (['WAITING_REPORT', 'UNKNOWN'].includes(status)) return 'unknown';
      if (['DELIVERED', 'ACCEPTED'].includes(status)) return 'success';
      return 'error';
    },
    onFetch(event) {
      this.page = event.page;
      this.rows = event.rows;
      this.sortOrder = event.sortOrder;
      this.sortField = event.sortField;
      this.fetchPage();
    },
    async fetchPage() {
      this.loading = true;
      const notifications = await api.getNotificationHistory(
        this.id,
        this.page,
        this.rows,
        this.sortField,
        this.sortOrder === -1,
        this.method
      );
      this.notifications = notifications.content;
      this.totalRecords = notifications.totalElements;
      this.loading = false;
    },
  },
};
</script>
<style scoped>
:deep(.no-expander .p-row-toggler) {
  opacity: 0;
  pointer-events: none;
}
</style>
